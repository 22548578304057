(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        const nav = $("#layout_nav");
        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });

        let links = fn.find(".elm_nav__item"),
            timeout;

        links.on("mouseenter",function(){
            let el = $(this);
            clearTimeout(timeout);
            $.fn.close = function() {
                let elm = $(this);
                elm.removeClass("state--toggled");
                timeout = setTimeout(function(){
                    elm.attr("aria-expanded","false");
                },300);
            };
            $.fn.open = function() {
                let el = $(this);
                el.addClass("state--toggled").attr("aria-expanded","true");
                if(!el.next(".elm_nav__submenu").length) {
                    el.one("mouseleave",function () {
                        el.close();
                    })
                }
                else {
                    fn.one("mouseleave",function () {
                        setTimeout(function () {
                            el.close();
                        },300);
                    })
                }
            };

            html.one("click", function() {
                el.close();
            });
            win.one("scroll", function() {
                el.close();
            });
            if (!el.hasClass("state--toggled")) {
                el.open();
            }
            links.not(el.closest(".elm_nav__item")).close();
        });

        if (nav.length) {
            let animation_delay = 60,
                animation_duration = 600;

            $.fn.fn_animate = function(menu_id,direction,val) {
                let el = $(this),
                    animation_delay = el.parent().find("[data-menu=\""+menu_id+"\"]").data("menu-delay") + animation_duration;

                let anim_out, anim_in;
                if (direction === "in") {
                    anim_out = "animate--out-left";
                    anim_in = "animate--in-right"
                } else {
                    anim_out = "animate--out-right";
                    anim_in = "animate--in-left"
                }

                el.closest(".elm_content").addClass("animated");

                el.addClass(anim_out).parent().find("[data-menu=\""+menu_id+"\"]").addClass(anim_in);


                if (nav.find("[data-menu-breadcrumbs]").children("li").length === 1) {
                    nav.find("[data-submenu-back]").removeClass("state--active");
                }

                setTimeout(function(){
                    el.removeClass(anim_out+" state--active").parent().find("[data-menu=\""+menu_id+"\"]").removeClass(anim_in).addClass("state--active");
                    el.closest(".elm_content").removeClass("animated");
                    if (direction === "in") {
                        nav.find("[data-menu-breadcrumbs]").children("li").removeClass("state--active").parent().append("<li class='state--active' data-menu-id='"+menu_id+"'>"+val+"</li>").find("state--active");
                    }

                    if (nav.find("[data-menu-breadcrumbs]").children("li").length > 1) {
                        nav.find("[data-submenu-back]").addClass("state--active");
                    }
                }, animation_delay);
            };

            nav.find("[data-menu]").each(function() {
                let el = $(this),
                    el_length = el.children("li").length - 1;

                el.children("li").each(function(i){
                    $(this).css("animation-delay", i*animation_delay+"ms");
                });

                el.attr("data-menu-delay", el_length * animation_delay);
            });


            nav.on("click", "[data-menu-breadcrumbs] [data-menu-id]", function() {
                let el = $(this),
                    menu_active = nav.find("[data-menu].state--active"),
                    menu_id = el.data("menu-id");

                if (!el.hasClass("state--active")) {
                    el.addClass("state--active").nextAll().remove();
                    menu_active.fn_animate(menu_id, "out");
                }
            });


            nav.on("click", "[data-submenu]", function(e){
                e.preventDefault();
                let el = $(this),
                    val = el.prev().find("span").text(),
                    menu_id = el.data("submenu");

                el.closest("[data-menu]").fn_animate(menu_id, "in", val);
            });

            nav.on("click", "[data-submenu-back]", function(e){
                e.preventDefault();
                let menu = nav.find("[data-menu].state--active"),
                    menu_prev = $("[data-menu-breadcrumbs] [data-menu-id].state--active").prev(),
                    menu_id = menu_prev.data("menu-id");

                menu_prev.addClass("state--active").nextAll().remove();
                menu.fn_animate(menu_id, "out");
            });
        }
    }
})(jQuery);