(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-carousel-image]"),
            caption = selector.find("[data-carousel-caption]"),
            autoplay = slider.data("carousel-image"),
            dots = selector.find(".elm_flickity_dots").find("li");

        $.lib_flickity(function(){
            $.importScript(cdnjs.flickity_sync,()=>{
                if (slider.children().length > 1) {
                    slider.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: false,
                        pageDots: false,
                        wrapAround: true,
                        contain: true,
                        autoPlay: autoplay,
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: true,
                        draggable: true,
                        sync: caption[0]
                    });
                    caption.flickity({
                        percentPosition: false,
                        pageDots: false,
                        wrapAround: true,
                        contain: true,
                        prevNextButtons: false,
                        draggable: false
                    });

                    let flkty = slider.data("flickity");
                    slider.on("select.flickity",function () {
                        dots.filter('.state--active').removeClass('state--active');
                        dots.eq(flkty.selectedIndex).addClass('state--active');
                    });

                    dots.on("click",function () {
                        slider.flickity('select', $(this).index(), true, false)
                    })
                }
                else {
                    selector.find(".elm_flickity_dots").remove();
                }
            });
        });

        selector.on("click","[data-scroll]",function () {
           selector.next("section").anchor_anim(500);
        });

        window.lui[name] = comp;
    }
})("comp_visual_main");