(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let content = selector.find(".wrp_body__content"),
            buttons = selector.find(".wrp_body__buttons"),
            milestones_nav = selector.find(".wrp_comp__foot").find("[data-scroll]"),
            milestones = content.find(".part_item_timeline_milestone"),
            entries = content.find("[data-entry]"),
            headings = selector.find(".elm_block__heading"),
            current,
            next,
            offsets = [];

        function calcOffsets(){
            offsets = [];
            entries.each(function () {
                let el = $(this)[0];
                offsets.push(el.offsetLeft - buttons.outerWidth());
            });
        }
        calcOffsets();

        win.on("resize",calcOffsets());

        $.importScript(cdnjs.lodash,function () {
            buttons.on("click","button",function () {
                let el = $(this);
                let move = 1;
                current = entries.filter(".state--selected");

                if((el.data("move") === "next") && entries.index(current) === entries.length -1) {
                    return;
                }
                if(el.data("move") === "previous") {
                    if(entries.index(current) === 0) {
                        return;
                    }
                    move = -1;
                }

                next = $(entries[entries.index(current)+move]);

                content.stop().animate({
                    scrollLeft: next[0].offsetLeft - buttons.outerWidth()
                });
            });

            milestones_nav.on("click",function () {
                let el = $(this);
                let milestone = milestones.filter("[data-entry=\""+el.data("scroll")+"\"]");

                content.stop().animate({
                    scrollLeft: milestone[0].offsetLeft - buttons.outerWidth()
                });
            });

            content.on("scroll",_.debounce(function () {
                entries = content.find("[data-entry]");

                let offset = content.scrollLeft();
                let cur = 0;
                let el = entries[0];
                let milestone = "";

                offsets.forEach(function(val, index){
                    if(Math.abs(offset - val) < Math.abs(offset - cur)) {
                        cur = val;
                    }
                });

                el = $(entries[offsets.indexOf(cur)]);

                entries.filter(".state--selected").removeClass("state--selected");
                el.addClass("state--selected");
                headings.each(function(){
                    let heading = $(this);
                    if(heading.is(":visible") && heading.data("block") !== el.closest(".col--block").data("block")){
                        heading.fadeOut();
                    }
                });
                headings.filter("[data-block=\""+el.closest(".col--block").data("block")+"\"]").fadeIn();

                if(el.is(".part_item_timeline_milestone")){
                    milestone = el;
                }
                else {
                    milestone = $(el.closest("li").prevAll(".col--milestone").get(0)).find(".part_item_timeline_milestone");
                }

                milestones_nav.filter(".state--active").removeClass("state--active");
                milestones_nav.filter("[data-scroll=\""+milestone.data("entry")+"\"]").addClass("state--active");

                content.stop().animate({
                    scrollLeft: el[0].offsetLeft - buttons.outerWidth()
                });
            },250));
        });

        window.lui[name] = comp;
    }
})("comp_timeline");