(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let gallery = selector.find(".elm_gallery");

        $.importScript(cdnjs.lightgallery, function () {
            gallery.lightGallery({
                thumbnail: true,
                selector: ".elm_gallery__item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false
            });
        });

        window.lui[name] = comp;
    }
})("comp_gallery_list");