(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-carousel]"),
            autoplay = slider.data("carousel");

        $.lib_flickity(function(){
            if (slider.children().length > 1) {
                slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: false,
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: true,
                    draggable: true,
                    cellAlign: "left"
                });

                selector.on("click","[data-carousel-button]",function () {
                    slider.flickity($(this).data("carousel-button"),true, false);
                })
            }
        });

        $.importScript(cdnjs.lightgallery, function () {
            let slides = selector.find(".elm_instagram__post");
            let gallery = slider.lightGallery({
                thumbnail: true,
                selector: ".elm_instagram__post",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: false,
                download: true,
                autoplay: false,
                autoplayControls: false,
            });

            gallery.on("onAfterOpen.lg",function (event) {
                $('.lg-toolbar').append('<a class="lg-icon" href="'+$(slides[0]).data("ig")+'" target="_blank" rel="nofollow nooopener"><span class="icon--instagram"></span></a>');
            });
            gallery.on("onAfterSlide.lg",function (event, prevIndex, index) {
                $('.lg-toolbar').find(".icon--instagram").parent(".lg-icon").attr("href",$(slides[index]).data("ig"));
            })
        });

        window.lui[name] = comp;
    }
})("comp_gallery_instagram");