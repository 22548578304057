(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let links = selector.find(".wrp_comp__head").find(".elm_category__item"),
            select = selector.find(".wrp_comp__head").find(".part_ui_select").find("select");

        links.on("click",function () {
            let el = $(this);

            links.filter(".state--active").removeClass("state--active");
            el.addClass("state--active");
            select[0].selectedIndex = links.index(el);
            //cary mary AJAX
        });

        select.on("change",function () {
            links.eq($(this)[0].selectedIndex).trigger("click");
        });

        window.lui[name] = comp;
    }
})("comp_list_articles");